<template>
    <section class="view payment-methods">
        <view-title v-bind:title="$t('message.payment-methods.title')"></view-title>
        <div class="container py-5">
            <div class="row">
                <div class="col-md-3">
                    <ul class="nav flex-column mb-4"
                        v-if="$i18n.locale === 'en'">
                        <li class="nav-item">
                            <a class="nav-link" href="#paypal">PayPal</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#alipay">AliPay</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#wechat-pay">WeChat Pay</a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-4"
                        v-if="$i18n.locale === 'zh-hk'">
                        <li class="nav-item">
                            <a class="nav-link" href="#paypal">PayPal</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#alipay">支付寶</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#wechat-pay">微信支付</a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-4"
                        v-if="$i18n.locale === 'zh-cn'">
                        <li class="nav-item">
                            <a class="nav-link" href="#paypal">PayPal</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#alipay">支付宝</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#wechat-pay">微信支付</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'en'">
                    <h5 id="paypal">PayPal</h5>
                    <p class="text-justify">
                        PayPal is an account-based system that lets anyone with an email address securely send and receive online payments using their credit card or bank account. To make a payment through PayPal, you first need to open an account with PayPal. Please visit PayPal's official website for detailed information on how to create an account and make payments.
                    </p>

                    <h5 id="alipay">AliPay</h5>
                    <p class="text-justify">
                        Alipay is an account-based system that lets anyone with an email address securely send and receive online payments using their credit card or bank account. To make a payment through Alipay, you first need to open an account with Alipay. Please visit Alipay's official website for detailed information on how to create an account and make payments.
                    </p>

                    <h5 id="wechat-pay">WeChat Pay</h5>
                    <p class="text-justify">
                        Consumers can see related product information and transaction guides on their phone after users scan the products QR codes.
                    </p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-hk'">
                    <h5 id="paypal">PayPal</h5>
                    <p class="text-justify">
                        透過 PayPal 網上收付款既快速又安全，無需透露你的財務資料。PayPal乃一個以戶口為主的系統，容許任何人以電郵地址，透過信用卡及銀行戶口，安全地支付及存入網上各類帳項。選擇以PayPal付款，您須於PayPal先開立戶口 (有關詳情請瀏覽PayPal官方網站 )。
                    </p>

                    <h5 id="alipay" >支付寶</h5>
                    <p class="text-justify">
                        支付宝交易，是指網上交易的買賣雙方，明示接受支付宝公司作為網路交易仲介，使用支付宝公司為買賣雙方提供的網上交易管理系統及信用仲介（代收付貨款等）服務的網上交易。 選擇以支付宝付款，您須於支付宝先開立戶口 (有關詳情請瀏覽 支付宝官方網站 )。
                    </p>

                    <h5 id="wechat-pay">微信支付</h5>
                    <p class="text-justify">
                        為不同的商品創建不同的QR碼。客戶掃描商品代碼後，可以在手機上看到相關的產品信息和交易指南。
                    </p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-cn'">
                    <h5 id="paypal">PayPal</h5>
                    <p class="text-justify">
                        透过 PayPal 网上收付款既快速又安全，无需透露你的财务资料。 PayPal乃一个以户口为主的系统，容许任何人以电邮地址，透过信用卡及银行户口，安全地支付及存入网上各类帐项。选择以PayPal付款，您须于PayPal先开立户口 (有关详情请浏览PayPal官方网站 )。
                    </p>

                    <h5 id="alipay">支付宝</h5>
                    <p class="text-justify">
                        支付宝交易，是指网上交易的买卖双方，明示接受支付宝公司作为网路交易仲介，使用支付宝公司为买卖双方提供的网上交易管理系统及信用仲介（代收付货款等）服务的网上交易。选择以支付宝付款，您须于支付宝先开立户口 (有关详情请浏览 支付宝官方网站 )。
                    </p>

                    <h5 id="wechat-pay">微信支付</h5>
                    <p class="text-justify">
                        为不同的商品创建不同的QR码。客户扫描商品代码后，可以在手机上看到相关的产品信息和交易指南。
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "PaymentMethods"
    }
</script>

<style lang="scss" scoped>
    .payment-methods {

    }
</style>
